import { HomeSpecification } from '~/types/mock'

const WebAppDevBusiness: HomeSpecification[] = [
  {
    icon: 'sprite3DIconSmall_10',
    title: 'Online marketplaces',
    description:
      'Our web app development solutions for online marketplaces empower businesses to create robust platforms that connect buyers and sellers seamlessly.',
    link: '/case-studies/kids-activities-service-marketplace/',
  },
  {
    icon: 'sprite3DIconSmall_13',
    title: 'eCommerce',
    description:
      'Our web solutions enable businesses to create engaging online stores that boost conversions and maximize customer satisfaction.',
    link: '/case-studies/custom-multi-vendor-marketplace-platform/',
  },
  {
    icon: 'sprite3DIconSmall_14',
    title: 'Travel',
    description:
      'Our web app solutions for the travel industry help deliver exceptional customer experiences while simplifying complex processes.',
    link: '/case-studies/travel-management-platform/',
  },
  {
    icon: 'sprite3DIconSmall_15',
    title: 'Automotive',
    description:
      'Our web app solutions enable automotive businesses to improve online presence, enhance customer satisfaction, and drive revenue growth.',
    link: '/services/automotive-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_4',
    title: 'Insurance',
    description:
      'Our web solutions help insurance businesses streamline their workflows, improve efficiency, and deliver a seamless experience to policyholders.',
    link: '/services/insurance-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_11',
    title: 'Recruiting',
    description:
      'Our web app development solutions enable recruiting agencies and HR departments to save time and make data-driven hiring decisions.',
    link: '/case-studies/recruitment-progressive-web-application/',
  },
  {
    icon: 'sprite3DIconSmall_16',
    title: 'Rental',
    description:
      'Our rental web app solutions foster businesses to increase revenue and provide a convenient booking experience to their customers.',
    link: '/case-studies/accommodation-search-website-mvp/',
  },
  {
    icon: 'sprite3DIconSmall_17',
    title: 'Media',
    description:
      'Our web app solutions for the media industry help businesses engage their audience, drive user participation, and monetize their content effectively.',
    link: '/case-studies/news-aggregator/',
  },
  {
    icon: 'sprite3DIconSmall_18',
    title: 'Healthcare',
    description:
      'Our healthcare web app solutions enable companies to optimize their operations, enhance patient outcomes, and deliver personalized care.',
    link: '/case-studies/dental-imaging-management-system/',
  },
]

export default WebAppDevBusiness
