import { RequirementsSoftwareMock } from '~/types/mock'

const WebAppDevCost: RequirementsSoftwareMock[] = [
  {
    title: 'Single-store solution',
    list: [
      { item: 'Cost: $48k-$60k USD' },
      { item: 'Estimate: 1200-1500 hrs' },
    ],
  },
  {
    title: 'Vendor/sales platform',
    list: [
      { item: 'Cost: $60k-$80k USD' },
      { item: 'Estimate: 1500-2000 hrs' },
    ],
  },
  {
    title: 'Multistore portal',
    list: [
      { item: 'Cost: $100k-$160k USD' },
      { item: 'Estimate: 2500-4000 hrs' },
    ],
  },
  {
    title: 'E-learning solution',
    list: [
      { item: 'Cost: $80k-$200k USD' },
      { item: 'Estimate: 2000-5000 hrs' },
    ],
  },
  {
    title: 'Ecommerce platform',
    list: [
      { item: 'Cost: $140k-$200k USD' },
      { item: 'Estimate: 3500-5000 hrs' },
    ],
  },
]

export default WebAppDevCost
