import { Link } from 'gatsby'

import { ServicesProcessMock } from '~/types/mock'

const WebAppDevProcess: ServicesProcessMock[] = [
  {
    num: '01',
    title: 'Idea validation',
    descr: (
      <>
        Our
        <Link
          to="/services/product-discovery/"
          className="mx5"
          key="keyDescrProduct"
        >
          product discovery
        </Link>
        stage ensures that our team meets market demands, solves real user
        pains, and has the potential to achieve your desired business goals.
      </>
    ),
  },
  {
    num: '02',
    title: 'UI/UX designing',
    descr: (
      <>
        With our
        <Link to="/services/ui-ux-design/" className="mx5" key="keyDescrDesign">
          UI/UX design services
        </Link>
        you can cooperate with our experienced designers to create intuitive
        interfaces that provide seamless navigation and delightful interactions.
      </>
    ),
  },
  {
    num: '03',
    title: 'Web app developing',
    descr:
      'Our professional developers transform your visual concepts into fully functional web apps, involving you in regular progress updates and incorporating feedback.',
  },
  {
    num: '04',
    title: 'Testing process',
    descr: (
      <>
        By delivering comprehensive
        <Link
          to="/services/quality-assurance/"
          className="ml5"
          key="keyDescrTesting"
        >
          quality assurance services
        </Link>
        , we guarantee high-quality web applications that offer a smooth and
        seamless user experience.
      </>
    ),
  },
  {
    num: '05',
    title: 'Maintenance and support',
    descr:
      'Our commitment doesn’t end with launching your web app, as we proactively address technical issues to keep your web application secure and up-to-date.',
  },
]

export default WebAppDevProcess
