import { StaticImage, getImage } from 'gatsby-plugin-image'

import { CommonImageProps } from '~/types/common-props'
import { WorksTabsContent } from '~/types/data-array'
import { fileToImageLikeData } from '~/utils'
import useWebAppDevelopmentStaticQuery from '~/views/Services/WebAppDevelopment/useWebAppDevelopmentStaticQuery'

const photoClientWowner =
  '../../../assets/images/avatars/clients/client-wowner.png'
const photoClientImpact =
  '../../../assets/images/avatars/clients/client-impact.png'

const imageProps: CommonImageProps = {
  width: 50,
  height: 50,
}

const WebAppDevTabContent = (): WorksTabsContent[] => {
  const query = useWebAppDevelopmentStaticQuery()

  const portfolioImpact = getImage(fileToImageLikeData(query.portfolioImpact))
  const portfolioREW = getImage(fileToImageLikeData(query.portfolioREW))

  const tabContent = [
    {
      key: 'Fitness web app',
      gradient: 'gradientImpact',
      image: portfolioImpact,
      header: 'Fitness PWA',
      title:
        'Impact is a fitness app that connects trainers and their clients via desktops and smartphones. The application allows for assigning and tracking personal training and nutrition programs.',
      resultsLeft: '400+ exercises included',
      resultsRight: '2000+ workouts performed',
      link: 'fitness-progressive-web-application',
      review: [
        '“Codica changed the solution and added new features per our requests, and ensured the proper work of the app even with high loads on servers.',
        <div className="mb-2" key="descrTwo" />,
        'As a result, Codica delivered an app that our clients and trainers love. We are very happy with our collaboration and strongly recommend Codica as a reliable web development team.”',
      ],
      get clientPhoto() {
        return (
          <StaticImage
            src={photoClientImpact}
            alt={`${this.clientName} | Codica`}
            title={this.clientName}
            {...imageProps}
          />
        )
      },
      clientName: 'Richard Gunter',
      clientPosition: 'Founder of Impact Personal Training',
    },
    {
      key: 'Recruiting platform',
      gradient: 'gradientRew',
      image: portfolioREW,
      header: 'Recruitment app',
      title:
        'RecruitEverywhere is a product of the Intelligence Group that specializes in talent acquisition and labor markets. The recruiting platform allows hiring specialists all over the world in a couple of clicks.',
      resultsLeft: 'Created a platform to hire staff members globally',
      resultsRight: 'Implemented the PWA approach',
      link: 'recruitment-progressive-web-application',
      review:
        '“I am happy to recommend Codica as a reliable team that can deliver unique and powerful software products. They used all their expertise when building our application, and often advised on the best way to implement a particular feature.”',
      get clientPhoto() {
        return (
          <StaticImage
            src={photoClientWowner}
            alt={`${this.clientName} | Codica`}
            title={this.clientName}
            {...imageProps}
          />
        )
      },
      clientName: 'Geert-Jan Waasdorp',
      clientPosition: 'Co-Founder of RecruitEverywhere',
    },
  ]

  return tabContent
}

export default WebAppDevTabContent
