import { DevExpertiseCompanyMock } from '~/types/mock'

const WebAppDevExpertise: DevExpertiseCompanyMock[] = [
  {
    title: '9+',
    text: 'years of web app development experience',
    classNameText: 'maxWidth190',
  },
  {
    title: '50+',
    text: 'projects delivered',
    classNameText: 'maxWidth190',
  },
  {
    title: '$56+',
    text: 'mln raised by our clients',
    classNameText: 'maxWidth140',
  },
  {
    title: '60+',
    text: 'experts on board',
    classNameText: 'maxWidth190',
  },
]

export default WebAppDevExpertise
