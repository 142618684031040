import { DataTabList } from '~/types/data-array'

const WebAppDevTabList: DataTabList[] = [
  {
    name: 'Fitness web app',
  },
  {
    name: 'Recruiting platform',
  },
]

export default WebAppDevTabList
