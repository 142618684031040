import { ExpertiseSectionMock } from '~/types/mock'

const SaaSTrustedBusinesses: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIconSmall_7',
    title: 'Startups',
    description:
      'Codica is here to help startups bring their ideas to life and disrupt their industries. Our specialists work closely with startup entrepreneurs to develop scalable, cost-effective web apps aligning with their unique vision and goals.',
  },
  {
    icon: 'sprite3DIconSmall_8',
    title: 'Small businesses',
    description:
      'At our web development services company, we believe every small business deserves a robust online presence. Our web solutions meet your needs, budget, and industry, ensuring your small business stands out.',
  },
  {
    icon: 'sprite3DIconSmall_9',
    title: 'Mid-scale businesses',
    description:
      'We recognize that mid-scale businesses face unique challenges as they strive to expand their reach and optimize their operations. So, our web application development services are designed to address these challenges head-on.',
  },
  {
    icon: 'sprite3DIconSmall_19',
    title: 'Enterprises',
    description:
      'Regarding enterprises, Codica offers comprehensive web app development services that cater to their complex requirements and expansive user base. We understand the need for robust security and seamless integration with existing systems.',
  },
]

export default SaaSTrustedBusinesses
