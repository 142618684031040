import { ExpertiseSectionMock } from '~/types/mock'

const WebAppDevServices: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIcon_10',
    title: 'Web app consulting',
    description:
      'We take pride in our consultative approach, working closely with clients. Thus, we can fully understand their unique business objectives, target audience, and desired outcomes.',
  },
  {
    icon: 'sprite3DIcon_12',
    title: 'Web app design',
    description:
      'Our skilled design team combines creativity and functionality to create visually stunning, intuitive interfaces and engaging user experiences.',
  },
  {
    icon: 'sprite3DIcon_13',
    title: 'Web app development',
    description:
      'From ideation to deployment, we follow industry best practices and agile development approach to deliver a high-quality product that meets your requirements.',
  },
  {
    icon: 'sprite3DIcon_6',
    title: 'Web app migration to the cloud',
    description:
      'The Codica team ensures a smooth and secure transfer of your web application’s data and functionalities, minimizing downtime and maximizing performance.',
  },
  {
    icon: 'sprite3DIcon_11',
    title: 'Web app integration',
    description:
      'Our web application integration services enable seamless connections between software components, APIs, databases, or other third-party services.',
  },
  {
    icon: 'sprite3DIcon_20',
    title: 'Web app support',
    description:
      'Cooperating with our dedicated support team, you can focus on your core business pains while we care for your web application’s stability and reliability.',
  },
]

export default WebAppDevServices
