import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useWebAppDevelopmentStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      portfolioREW: file(
        relativePath: { eq: "homepage/discover-works-portfolio-11.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      portfolioImpact: file(
        relativePath: { eq: "homepage/discover-works-portfolio-12.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      clientPMK: file(
        relativePath: { eq: "avatars/clients/large-photo/client-pmk.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  return query
}

export default useWebAppDevelopmentStaticQuery
