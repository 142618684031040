import { FAQ } from '~/types/data-array'

const WebAppDevFAQ: FAQ[] = [
  {
    question: 'What is web app development?',
    answer:
      'Web application development is the process of building software applications that run on web browsers or are accessed through web interfaces. These applications are designed to be available over the internet, providing users with a seamless experience across various devices.',
  },
  {
    question: 'How much does it cost to develop a web app?',
    answer:
      'Factors such as complexity, features, tech stack, design, and more all play a role in determining the web app development cost. A standard MVP for a web app can be estimated at $30,000-$50,000; a more complex web app with advanced functionalities costs $50,000 to several hundred thousand dollars.',
  },
  {
    question: 'How long does it take to build a web application?',
    answer:
      'The timeline for building a web app can vary depending on the app’s complexity, features, design, and more. Developing a small to medium-sized web app with basic functionalities typically takes 3-5 months. Creating more complex web apps with advanced features can take 6-12 months.',
  },
]

export default WebAppDevFAQ
