import { ExpertiseSectionMock } from '~/types/mock'

const webAppDevPractices: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_42',
    title: 'Comprehensive planning',
    description:
      'Thorough planning is crucial for successful web app development. This allows us to create a detailed roadmap, define project milestones, and establish clear expectations.',
  },
  {
    icon: 'spriteIcon_56',
    title: 'User-centric design',
    description:
      'With user research and testing, we ensure our applications are optimized for usability, accessibility, and overall user satisfaction.',
  },
  {
    icon: 'spriteIcon_68',
    title: 'Scalability and performance',
    description:
      'We optimize code, utilize efficient algorithms, and leverage scalable infrastructure to ensure our apps adapt and grow alongside our clients’ businesses.',
  },
  {
    icon: 'spriteIcon_25',
    title: 'Cross-platform compatibility',
    description:
      'The Codica team ensures that our web apps are compatible across browsers, devices, and operating systems, providing that our clients’ apps reach a wide audience.',
  },
  {
    icon: 'spriteIcon_22',
    title: 'Agile development methodology',
    description:
      'Our specialists assume agile methodologies to boost collaboration, adaptability, and frequent clients feedback throughout the development process.',
  },
  {
    icon: 'spriteIcon_90',
    title: 'Quality assurance',
    description:
      'We conduct regression testing, create testing strategies, write acceptance criteria to ensure that our web apps meet the highest quality standards.',
  },
]

export default webAppDevPractices
