import { ExpertiseSectionMock } from '~/types/mock'

const WebAppDevExperience: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_43',
    title: 'High-quality development',
    description:
      'Our skilled developers deliver top-quality code and follow industry best practices, ensuring your solution is robust, secure, and scalable.',
  },
  {
    icon: 'spriteIcon_61',
    title: 'Customized solutions',
    description:
      'We specialize in providing tailored web app development services, ensuring your solution is designed to meet your unique business requirements.',
  },
  {
    icon: 'spriteIcon_35',
    title: 'Timely delivery',
    description:
      'Our experts understand the importance of meeting deadlines and ensuring efficient resource allocation, as well as effective communication.',
  },
  {
    icon: 'spriteIcon_16',
    title: 'Cost-effectiveness',
    description:
      'We strive to provide cost-effective and transparent pricing models without compromising quality, helping you maximize your return on investment.',
  },
  {
    icon: 'spriteIcon_68',
    title: 'Reliable partnership',
    description:
      'Our expert team prioritizes open communication, collaboration, and client satisfaction, supporting you throughout your development journey.',
  },
  {
    icon: 'spriteIcon_27',
    title: 'Ongoing support',
    description:
      'Codica provides comprehensive post-development support and maintenance services, providing regular updates and technical assistance.',
  },
]

export default WebAppDevExperience
