import { ExpertiseSectionMock } from '~/types/mock'

const WebAppDevBenefits: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_64',
    title: 'Ease entrance',
    description:
      'It reduces the obstacle to entry, making it easier and more cost-effective for businesses to reach a wider audience.',
  },
  {
    icon: 'spriteIcon_34',
    title: 'Security',
    description:
      'Web apps enable you to implement strong security measures, ensuring sensitive information’s confidentiality, integrity, and availability.',
  },
  {
    icon: 'spriteIcon_78',
    title: 'Accessibility',
    description:
      'The accessibility of web apps allows for seamless access across devices, enabling you to cater to diverse user preferences.',
  },
  {
    icon: 'spriteIcon_25',
    title: 'Seamless updates',
    description:
      'With web apps, you can maintain consistency across your user base and swiftly address any issues or improvements.',
  },
]

export default WebAppDevBenefits
